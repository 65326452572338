<template>
  <div class="your-class">
    <div style="width: 100%; height: 100%">
      <div class="swiper-container swiper1">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_001.jpg" alt=""/>
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_002.png" alt="" />
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_001.jpg" alt="" />
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_002.png" alt=""  />
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_002.png" alt=""/>
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_002.png" alt="" />
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_002.png" alt=""  />
          </div>
        </div>
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img src="../assets/images/city_002.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
export default {
  name: "Test",
  data() {
    return {
      swiper: [],
    };
  },
  methods: {},
  mounted() {
    this.swiper = new Swiper('.swiper-container.swiper1',{
      loop:true,
      speed:3000,
      autoplay:true
    })
  },
};
</script>

<style scoped>
/* .swiper1 {
  width: 200px;
  background-color: antiquewhite;
  height: 1000px;

} */
.swiper-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color:antiquewhite;
  margin: 0;
  padding: 0;
}
/* .swiper-slide {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
} */
.swiper-slide img{
  width: 500px;
  height: 450px;
}
</style>